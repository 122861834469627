<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
			}
		},
		async mounted() {
			await this.ready();
            this.$set(this.$root, 'page', this);
		},
	};
</script>
<template>
	<div>
		<section id="slider" class="slider-element d-none d-sm-block d-md-block d-lg-block clearfix" :style="'height: 570px; padding: 30px 0; background: url('+baseUrl+'/frontend/images/hero-program.jpg) no-repeat 50% center;'">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 m_top_80">
                            <div class="wrap_hero_txt">
                                <h2>Program </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <section class="d-sm-none d-md-none d-lg-none" style="margin-top: 60px;"> 
        	<VImg :src="baseUrl+'/frontend/images/program-mobile.jpg'" alt="Program Visi Viyata Lestari" title="Program Visi Viyata Lestari"></VImg>
        </section>

        <section id="content" style="overflow: visible">
		    <div class="content-wrap p-0">
		        <div class="clear"></div>
		        <div class="section bg_f9f9f9  notopmargin nobottommargin">
		            <div class="container clearfix">
		                <div class="row justify-content-center">
		                    <div class="col-md-9">
		                        <article class="program_wrapper">
		                            <div class="row">
		                                <div class="col-md-3">
		                                    <div class="program_name">
		                                        <h3>Pengembangan Pendidikan dan Sekolah  </h3>
		                                        <div class="numb">
		                                            <h2>1</h2>
		                                        </div>
		                                    </div>
		                                </div>
		                                <div class="col-md-2">
		                                    <div class="wrap_ic">
		                                    	<VImg :src="baseUrl+'/frontend/images/school.png'" alt="Pengembangan Pendidikan dan Sekolah" title="Pengembangan Pendidikan dan Sekolah"></VImg>
		                                    </div>
		                                </div>
		                                <div class="col-md-7">
		                                    <div class="program_desc">
		                                        <p>Pendidikan bagi kami adalah kunci penting bagi masa depan suatu bangsa.
		                                            Kami menyadari tanggung jawab besar ini tidak bisa dilaksanakan oleh pemerintah tanpa adanya partisipasi dari civil society dan sektor swasta. Sebagai bagian dari civil society kami merintis berbagai program di bidang peningkatan pendidikan mulai dari peningkatan kapasitas guru, manajemen sekolah, pengembangan literasi dan juga pendidikan vokasi. Saat ini kami dengan beberapa pihak sedang mengembankan bagaimana kesadaran lingkungan dan pembangunan berkelanjutan dapat menjadi bagian penting dalam proses pendidikan di Indonesia.
		                                        </p>
		                                    </div>
		                                </div>
		                            </div>
		                        </article>
		                        <article class="program_wrapper">
		                            <div class="row">
		                                <div class="col-md-3">
		                                    <div class="program_name">
		                                        <h3>Mitra Tanggung Jawab Sosial Perusahaan </h3>
		                                        <div class="numb">
		                                            <h2>2</h2>
		                                        </div>
		                                    </div>
		                                </div>
		                                <div class="col-md-2">
		                                    <div class="wrap_ic">
		                                    	<VImg :src="baseUrl+'/frontend/images/responsibility.png'" alt="Mitra Tanggung Jawab Sosial Perusahaan" title="Mitra Tanggung Jawab Sosial Perusahaan"></VImg>
		                                    </div>
		                                </div>
		                                <div class="col-md-7">
		                                    <div class="program_desc">
		                                        <p>
		                                            International Standard Organization (ISO) melalui serangkaian diskusi dengan para pemangku
		                                            kepentingan  mengembangkan ISO 26000: Guidance on Social Responsibility yang banyak menjadi panduan banyak perusahaan untuk melaksanakan program tanggung jawab sosia l dan lingkungan atau sering dikenal sebagai Corporate Social Responsibility . Dalam konteks Indonesia Undang undang No 40/2007 tentang Persereon Terbatas memberikan panduan bagi seluruh perusahaan untuk dapat melakukan tanggung jawab sosial dan lingkungan.  Dengan pengalaman panjang, kami tertarik  menjadi mitra perusahaan dan juga pemerintah untuk dapat mengembangkan berbagai program tanggung jawab sosial dan lingkungan perusahaan dalam bidang pendidikan, kesehatan, ekonomi,  sosial budaya dan lingkungan untuk lebih efektif dan memberikan dampakpositif.
		                                        </p>
		                                    </div>
		                                </div>
		                            </div>
		                        </article>
		                        <article class="program_wrapper">
		                            <div class="row">
		                                <div class="col-md-3">
		                                    <div class="program_name">
		                                        <h3>Kemitraan untuk Pendidikan dan Pembangunan Berkelanjutan</h3>
		                                        <div class="numb">
		                                            <h2>3</h2>
		                                        </div>
		                                    </div>
		                                </div>
		                                <div class="col-md-2">
		                                    <div class="wrap_ic">
		                                    	<VImg :src="baseUrl+'/frontend/images/education.png'" alt="Kemitraan untuk Pendidikan dan Pembangunan Berkelanjutan" title="Kemitraan untuk Pendidikan dan Pembangunan Berkelanjutan"></VImg>
		                                    </div>
		                                </div>
		                                <div class="col-md-7">
		                                    <div class="program_desc">
		                                        <p>Kami menyadari pentingnya kolaborasi antara pemerintah, LSM, perguruan
		                                            tinggi dan perusahaan untuk meningkatkan kualitas pendidikan di Indonesia dan juga mendorong tercapai pembangunan berkelanjutan. Paritsipasi dan kolaborasi semua pihak menjadi kunci keberhasilan hal ini. Untuk itu kami  mencoba untuk berperan aktif dalam berbagai inisiatif kemitraan dan memfasilitasi terwujudnya kolaborasi antara pemangku kepentingan untuk pendidikan yang berkualitas dan pembangunan berkelanjutan .
		                                        </p>
		                                    </div>
		                                </div>
		                            </div>
		                        </article>
		                        <article class="program_wrapper">
		                            <div class="row">
		                                <div class="col-md-3">
		                                    <div class="program_name">
		                                        <h3>Studi dan diseminasi informasi </h3>
		                                        <div class="numb">
		                                            <h2>4</h2>
		                                        </div>
		                                    </div>
		                                </div>
		                                <div class="col-md-2">
		                                    <div class="wrap_ic">
		                                    	<VImg :src="baseUrl+'/frontend/images/pendidikan.png'" alt="Studi dan diseminasi informasi" title="Studi dan diseminasi informasi"></VImg>
		                                    </div>
		                                </div>
		                                <div class="col-md-7">
		                                    <div class="program_desc">
		                                        <p>Dalam mencapai tujuan dari Yayasan kami melaksanakan berbagai studi
		                                            mengenai dunia pendidikan di Indonesia dan pembangunan berkelanjutan. Kami juga melakukan diseminasi berbagai informasi terkait pendidikan dan pembangunan berkelanjutan yang harapannya membuat para pemangku kepentingan dapat berkontribusi dan berpartisipasi dalam dunia pendidikan dan pembangunan berkelanjutan.
		                                        </p>
		                                    </div>
		                                </div>
		                            </div>
		                        </article>
		                    </div>
		                </div>
		            </div>
		        </div>
		        <div class="clear"></div>
		    </div>
		</section>
	</div>
</template>